/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { S3Client, ListObjectsV2Command } from '@aws-sdk/client-s3';
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ScrollTop } from 'primereact/scrolltop';
import { Toast } from 'primereact/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { COMBO_NAMES, URL, VALID_FOLDERS } from './constants';
import { GalleryImg } from './components/GalleryImg';
import { UIButton } from './components/UIButton';
import { Button } from 'primereact/button';
import Bug from './bug.png';
import './App.css';
import { useKeyPress } from './hooks/useKeyPress';

const listBucketContents = async (): Promise<string[]> => {
  const client = new S3Client({
    region: 'us-east-1',
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
    },
  });

  const command = new ListObjectsV2Command({
    Bucket: 'meltpics',
  });

  try {
    let isTruncated = true;
    let contents: string[] = [];

    while (isTruncated) {
      const { Contents, IsTruncated, NextContinuationToken } =
        await client.send(command);
      contents = contents.concat(
        (Contents || [])
          .filter(({ Key }) =>
            VALID_FOLDERS.find((folder) => Key?.startsWith(folder)),
          )
          .map(({ Key }) => `${URL}${Key}`),
      );
      isTruncated = !!IsTruncated;
      command.input.ContinuationToken = NextContinuationToken;
    }
    console.log('contents', contents);
    contents = _.shuffle(contents);
    return contents;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState<string[]>([]);
  const [collection, setCollection] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [maxVisible, setMaxVisible] = useState(30);
  const [offset, setOffset] = useState<number | null>(null);
  const [zoomImage, setZoomImage] = useState<string | null>(null);
  const [zoomImageIndex, setZoomImageIndex] = useState<number | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [bugMode, setBugMode] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const toast: any = useRef(null);

  const throttled = useRef(
    _.throttle((visible, loading) => {
      if (
        !loading &&
        window.innerHeight + window.scrollY >= document.body.offsetHeight
      ) {
        setMaxVisible(visible + 30);
      }
    }, 1000),
  );
  useEffect(
    () => throttled.current(maxVisible, isLoading),
    [offset, isLoading, maxVisible],
  );
  const setScroll = () => {
    setOffset(window.scrollY);
  };

  useEffect(() => {
    listBucketContents().then((result) => {
      setImages(result);
      setIsLoading(false);
    });

    window.addEventListener('scroll', setScroll);
    return () => {
      window.removeEventListener('scroll', setScroll);
    };
  }, []);

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchValue(e.currentTarget.value);
  };
  const collectionView = location.pathname === '/collection';
  const searching = location.pathname.startsWith('/search/');

  let displayedImages: string[];
  if (searching) {
    const searchTerms = location.pathname.split('/')[2].split('-');
    const searchInclude = searchTerms.filter((s) => !s.startsWith('_'));
    const searchExclude = searchTerms.filter((s) => s.startsWith('_'));
    displayedImages = images.filter((i) => {
      const include = searchInclude.every((s) => {
        if (s.endsWith('.')) {
          const path = i
            .split('/')
            .slice(0, i.split('/').length - 1)
            .join('/');
          return path.endsWith(`/${s.replace('.', '')}`);
        }
        return i.includes(s);
      });
      const exclude = !searchExclude.some((s) => i.includes(s));
      return include && exclude;
    });
  } else if (collectionView && location.search) {
    const decoded = atob(location.search.replace('?c=', '')).split(',');
    if (collection.length !== decoded.length) {
      setCollection(decoded.map((c) => `${URL}${c}`));
    }
    displayedImages = decoded.map((c) => `${URL}${c}`);
  } else {
    displayedImages = images;
  }
  if (!bugMode) {
    displayedImages = displayedImages.filter((img) =>
      [
        'bugs',
        'spiders',
        'arthropods',
        'cnidarians',
        'viscera',
        'echinoderms',
        'cephalopods',
        'mold',
        'crinoids',
        'cambrian',
        'fetus',
        'slug',
        'worm',
        'snake',
      ].every((i) => !img.includes(i)),
    );
  }

  let tags: string[] = [];
  if (zoomImage) {
    const segments = zoomImage.split('/');
    tags = segments
      .slice(3, segments.length - 1)
      .reduce((acc: string[], s: string) => {
        let res = [s.replace(/-/g, ' ')];
        if (COMBO_NAMES[s]) {
          res = COMBO_NAMES[s];
        }
        return [...acc, ...res];
      }, []);
  }

  const phone = window.screen.width <= 500;

  const randomButton = (
    <div className="col">
      {UIButton({
        id: 'random',
        color: '#17a2b8',
        icon: 'sync',
        onClick: () => {
          setImages(_.shuffle(images));
          navigate('/');
        },
      })}
    </div>
  );
  let buttons;
  if (!phone || menuOpen) {
    if (collectionView) {
      buttons = (
        <>
          <div className="col">
            {UIButton({
              id: 'clear',
              label: `clear ${
                collection.length > 0 ? `(${collection.length})` : ''
              }`,
              color: '#727272',
              onClick: () => {
                setCollection([]);
                navigate(`/`);
              },
              icon: 'trash',
            })}
          </div>
          <div className="col">
            {UIButton({
              id: 'copy',
              label: 'copy link',
              color: '#e35f4d',
              onClick: () => {
                navigator.clipboard.writeText(window.location.href);
                if (toast.current.show) {
                  toast.current.show({
                    severity: 'success',
                    summary: 'success!',
                    detail: 'copied URL to the clipboard 👍',
                  });
                }
              },
              icon: 'link',
            })}
          </div>
          {randomButton}
        </>
      );
    } else {
      buttons = (
        <>
          <div className="col">
            {UIButton({
              id: 'collection',
              label: `collection ${
                collection.length > 0 ? `(${collection.length})` : ''
              }`,
              color: '#e35f4d',
              onClick: () => {
                navigate(
                  `/collection?c=${btoa(
                    collection.map((c) => c.replace(URL, '')).join(','),
                  )}`,
                );
              },
              icon: collection.length === 0 ? 'heart' : 'heart-fill',
              props: {
                disabled: collection.length === 0,
              },
            })}
          </div>
          <div className="col">
            {UIButton({
              id: 'bugMode',
              label: bugMode ? 'BUG MODE' : 'no bug mode....',
              color: bugMode ? '#93bf32' : '#bd6be3',
              onClick: () => setBugMode(!bugMode),
              props: {
                style: {
                  fontSize: bugMode ? '1.25em' : '0.75em',
                  fontWeight: bugMode ? 'bold' : 'normal',
                  opacity: bugMode ? 1 : 0.8,
                  animation: bugMode ? 'shake 1.5s' : 'none',
                  animationIterationCount: 'infinite',
                },
              },
            })}
          </div>
          {randomButton}
        </>
      );
    }
  }

  let searchTerm = location.pathname.replace('/search/', '');
  let title = 'the melt zone';
  if (collectionView) {
    title += ' | collection';
    searchTerm = 'neat collection you got there :O';
  } else if (searching) {
    title += ` | search "${searchTerm.replace(/-/g, ' ').replace(/_/g, '-')}"`;
  }

  const leftPressed = useKeyPress('ArrowLeft');
  const rightPressed = useKeyPress('ArrowRight');
  useEffect(() => {
    if (zoomImage && zoomImageIndex !== null) {
      if (rightPressed && zoomImageIndex < images.length) {
        setZoomImageIndex(zoomImageIndex + 1);
        setZoomImage(images[zoomImageIndex + 1]);
      }
      if (leftPressed && zoomImageIndex > 0) {
        setZoomImageIndex(zoomImageIndex - 1);
        setZoomImage(images[zoomImageIndex - 1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftPressed, rightPressed]);
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <Toast ref={toast} position="bottom-right" />
      <div className="topbar">
        <div className="grid top-grid">
          <div className="col-auto bug-container">
            <img src={Bug} alt="bug" style={{ height: 40 }} />
          </div>
          <div className="col-9 lg:col-8">
            <div className="p-inputgroup">
              <input
                autoComplete="off"
                type="search"
                id="query"
                name="q"
                placeholder={
                  searchTerm && searchTerm !== '/'
                    ? `"${searchTerm
                        .replace(/-/g, ' ')
                        .replace(
                          /_/g,
                          '-',
                        )}" (${displayedImages.length.toLocaleString()})`
                    : 'search...'
                }
                value={searchValue}
                onChange={handleSearch}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    if (searchValue) {
                      navigate(
                        `/search/${searchValue
                          .replace(/[^\w\s-.=]/g, '')
                          .replace(/-/g, '_')
                          .replace(/ /g, '-')}`,
                      );
                      setSearchValue('');
                    }
                  }
                }}
                autoFocus
              />

              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={(e) => {
                  if (searchValue) {
                    navigate(
                      `/search/${searchValue
                        .replace(/[^\w\s-=]/g, '')
                        .replace(/ /g, '-')}`,
                    );
                    setSearchValue('');
                  }
                }}
              />
            </div>
          </div>
          {phone && (
            <div className="col-1 lg:hidden menu-container">
              {UIButton({
                id: 'menu',
                label: '',
                color: '#222',
                icon: menuOpen ? 'times' : 'bars',
                onClick: () => setMenuOpen(!menuOpen),
                props: {
                  style: {
                    fontSize: '2em',
                    marginLeft: -12,
                    height: 50,
                  },
                },
              })}
            </div>
          )}
          {buttons}
        </div>
      </div>
      {isLoading ? (
        <div className="loading-wrapper">
          <ProgressSpinner />
        </div>
      ) : (
        <ul className="image-gallery">
          {displayedImages.slice(0, maxVisible).map((image, idx) => (
            <li key={image}>
              <a
                onClick={() => {
                  setZoomImage(image);
                  setZoomImageIndex(idx);
                }}
              >
                <GalleryImg
                  src={image}
                  setCollection={setCollection}
                  collection={collection}
                  idx={idx}
                />
              </a>
            </li>
          ))}
        </ul>
      )}
      <Dialog
        showHeader={false}
        visible={!!zoomImage}
        onHide={() => {
          setZoomImage(null);
          setZoomImageIndex(null);
        }}
        closable={false}
        blockScroll
        draggable={false}
        dismissableMask
        contentStyle={{ padding: 0, overflow: 'visible' }}
      >
        {zoomImage && (
          <div>
            <div>
              <img
                alt="zoomed"
                className={`zoom-image${phone ? '-phone' : ''}`}
                src={zoomImage as string}
                onClick={() => setZoomImage(null)}
              />
            </div>
            {(tags || []).map((tag, idx) => {
              const start = (zoomImage.length % 90) * 4;
              const percentage = (start + (idx / tags.length) * 360) % 360;
              return (
                <Chip
                  key={tag}
                  label={tag}
                  onClick={() => {
                    navigate(`/search/${tag.replace(/ /g, '-')}`);
                    setSearchValue('');
                    setZoomImage(null);
                  }}
                  style={{
                    marginRight: 4,
                    marginTop: 4,
                    backgroundColor: `hsl(${percentage}deg 80% 80%)`,
                    color: '#000',
                    fontFamily: 'monospace',
                    cursor: 'pointer',
                  }}
                />
              );
            })}
          </div>
        )}
      </Dialog>
      <ScrollTop />
    </div>
  );
};

export default App;
