export const COMBO_NAMES: Record<string, string[]> = {
  'shrimp-krill': ['shrimp', 'krill'],
  'whip-scorpions-vinegaroons': ['whip scorpions', 'vinegaroons'],
  'lobsters-crayfish': ['lobsters', 'crayfish'],
  'triops-tadpole-shrimp': ['triops', 'tadpole shrimp'],
  'moroccan-boho': ['moroccan', 'boho'],
};
export const URL = 'https://meltpics.s3.amazonaws.com/';

export const VALID_FOLDERS = ['creatures', 'reference', 'vibes'];
