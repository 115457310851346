import React from 'react';
import { Button } from 'primereact/button';

export const UIButton = ({
  id,
  label,
  icon,
  onClick,
  color,
  props,
}: {
  id: string;
  label?: string;
  icon?: string;
  onClick: () => void;
  color: string;
  props?: Record<string, any>;
}) => (
  <Button
    id={id}
    key={id}
    type='button'
    className='top-button'
    onClick={onClick}
    {...props}
    style={{
      backgroundColor: color,
      borderColor: color,
      ...(props?.style || {}),
    }}
  >
    <div className='inner-btn' style={{ width: '100%' }}>
      {icon && <i className={`pi pi-${icon}`} style={{ marginRight: 10 }}></i>}
      <span>{label ?? id}</span>
    </div>
  </Button>
);
