import React from 'react';
import { ContextMenu } from 'primereact/contextmenu';
import { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import glitterBg from './glitterbg.gif';
import glitterBg2 from './glitterbg2.gif';
import glitterBg3 from './glitterbg3.gif';
import glitterBg4 from './glitterbg4.gif';
import { URL } from '../constants';

export const GalleryImg = ({
  src,
  setCollection,
  collection,
  idx,
}: {
  src: string;
  setCollection: (collection: string[]) => void;
  collection: string[];
  idx: number;
}) => {
  const [loaded, setLoaded] = useState(false);
  const [rotateDeg, setRotateDeg] = useState(0);
  const [spin, setSpin] = useState(false);
  const [spinSpeed, setSpinSpeed] = useState(10000);
  const navigate = useNavigate();
  const location = useLocation();

  const inCollection = collection.includes(src);
  const cm: any = useRef(null);
  const items = [
    {
      label: inCollection ? 'un-collect' : 'collect',
      icon: `pi pi-fw pi-heart${inCollection ? '' : '-fill'}`,
      command: () => {
        if (inCollection) {
          const newCollection = collection.filter((img) => img !== src);
          setCollection(newCollection);
          if (location.pathname === '/collection') {
            navigate(
              `/collection?c=${btoa(
                newCollection.map((c) => c.replace(URL, '')).join(','),
              )}`,
            );
          }
        } else {
          setCollection([...collection, src]);
        }
      },
    },
    {
      label: 'save',
      icon: 'pi pi-fw pi-download',
      command: () => {
        var link = document.createElement('a');
        link.download = src;
        link.href = src;
        link.click();
      },
    },
    {
      label: 'spin faster',
      icon: 'pi pi-fw pi-spinner',
      command: () => {
        setSpinSpeed(spinSpeed / 1.5);
      },
      visible: !!spin,
    },
    {
      label: spin ? 'stop' : 'spin',
      icon: `pi pi-fw pi-${spin ? 'stop-circle' : 'spinner'}`,
      command: () => {
        setSpin(!spin);
      },
    },
    { label: 'close', icon: 'pi pi-fw pi-times' },
  ];
  let border = 'none';
  if (inCollection && location.pathname !== '/collection') {
    border = '5px solid #e35f4d';
  }
  const bgs = [glitterBg, glitterBg2, glitterBg3, glitterBg4];
  const bg = bgs[src.length % bgs.length];
  return (
    <>
      <ContextMenu model={items} ref={cm} breakpoint="767px" />
      <div
        style={{
          backgroundImage: spin ? `url(${bg})` : 'none',
          backgroundAttachment: 'scroll',
          border,
          transform:
            `rotate(${rotateDeg}deg) ` + (loaded ? 'scale(1,1)' : 'scale(0,0)'),
        }}
      >
        <img
          className={'image' + (loaded ? ' loaded' : '')}
          src={src}
          alt="loaded"
          style={
            spin
              ? {
                  animationName: 'spin',
                  animationDuration: `${spinSpeed}ms`,
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear',
                  animationDirection: idx % 2 === 0 ? 'normal' : 'reverse',
                }
              : {}
          }
          onLoad={() => setLoaded(true)}
          onContextMenu={(e) => {
            if (cm.current?.show) {
              cm.current.show(e);
            }
          }}
          onMouseOver={(e) => setRotateDeg(2)}
          onMouseOut={(e) => setRotateDeg(0)}
        />
      </div>
    </>
  );
};
